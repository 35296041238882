import React, { useState, useEffect } from "react";
import "../Deeplink/index.css";
import { DebounceInput } from "react-debounce-input";
import Select from "react-select";
import { Card, CardBody, Col, Container, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, Spinner } from "reactstrap";
import "toastr/build/toastr.min.css";
import classnames from "classnames";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MetaTags } from "react-meta-tags";
// import CouponListNotification from "components/Notification/CouponListNotification";
// import { updatedAdminTemplates } from "service/notifactionService";
// import CouponCreateNotification from "components/Notification/CouponCreateNotification";
// import CouponLogsNotification from "components/Notification/CouponLogsNotification";
// import CreditUsageLogs from "components/Notification/CreditUsageLogs";
// import { showAlert } from "components/Common/AlertMessage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactInputMask from "react-input-mask";
import Client from "./Client";
import Createlink from "./Createlink";
import { mode } from "crypto-js";
import { PromoCodeDeepAPI } from "service/notifactionService";
import LinkList from "./LinkList";
// import UsernameCreateNotification from "components/Notification/UsernameCreateNotification";

// import { CouponCountAPI } from "service/notifactionService";

function Deeplink() {
  const [selectName, setSelectName] = useState("");
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [startDate, setStartDate] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [sideModel, setSideModel] = useState(false);

  function handleGetData(name, number) {
    toggleCustom(number);
    setSelectName(name);
  }

  const [LinkView, setLinkView] = useState(false);

  function getLinks(link) {
    setLinkView(link);
  }

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const [links, setLinks] = useState("");
  const [api, setApi] = useState(false)


  useEffect(() => {
    setSelectName("clients");

    const fetchData = async () => {
      setSelectName("clients");
      try {
        const data = {}; // Replace with the actual data you need to send
        const resp = await PromoCodeDeepAPI(data);
        if (resp.code === 200) {
          setLinks(resp.data);
          // Handle successful response
        } else {
          setLinks("");
          // Handle unsuccessful response
        }
      } catch (error) {
        // console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title> Deeplink | BrightCheck</title>
        </MetaTags>
        <Container fluid={true}>
          {/* {selectName == "clients" ? "Clients" : selectName == "create" ? "Create Link" : selectName == "list" ? "Link List" : ""}  */}

          {selectName == "list" ? (
            <>
              <Row>
                <Col xl="12">
                  <Row>
                    <Col md={"4"}>
                      <Card className="mini-stats-wid home-card23">
                        <CardBody>
                          <div className="d-flex align-items-center mb-3">
                            <p className="text-muted fw-medium mb-0  fw-bold">Total Links</p>
                          </div>
                          <div className="flex-grow-1">
                            <h1 className="mb-0">{links && links.totalLinks}</h1>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={"4"}>
                      <Card className="mini-stats-wid home-card23">
                        <CardBody>
                          <div className="d-flex align-items-center mb-3">
                            <p className="text-muted fw-medium mb-0  fw-bold">Claimed Links</p>
                          </div>
                          <div className="flex-grow-1">
                            <h1 className="mb-0">{links && links.claimedLinks}</h1>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={"4"}>
                      <Card className="mini-stats-wid home-card23">
                        <CardBody>
                          <div className="d-flex align-items-center mb-3">
                            <p className="text-muted fw-medium mb-0  fw-bold">Remaining Links</p>
                          </div>
                          <div className="flex-grow-1">
                            <h1 className="mb-0">{links && links.remainingLinks}</h1>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : selectName == "clients" ? (
            <div className="col-md-12">
              <div className="text-end  ">
                <button onClick={() => {setShowModal(!showModal), setApi(!api)}} className="btn btn-primary m-auto ms-3 ">
                  Add Partner
                </button>
              </div>
            </div>
          ) : null}

          <Breadcrumbs breadcrumbItem="Notification Settings" />
          {selectName == "list" ? (
            <>
              <div className="row">
                <div className="col-4 mb-3">
                  <Label className="form-label">Generated Date</Label>
                  <DatePicker
                    className="form-control "
                    selected={startDate}
                    onChange={(e) => setStartDate(e)}
                    dateFormat="MM/dd/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    minDate={moment("01/01/1947").toDate()}
                    autoComplete="off"
                    placeholderText="MM/DD/YYYY"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div>

                <div className="col-4 mb-3">
                <Label className="form-label">Transaction ID</Label>
                <DebounceInput maxLength={40} value={couponCode} debounceTimeout={300} minLength={2} name="filterCustomer" onChange={(e) => setCouponCode(e.target.value)} className="form-control field-search" placeholder="Transaction ID" />
              </div>

              <div className="col-4 mb-3">
              <Label className="form-label">&nbsp;</Label>
<button onClick={() => {setCouponCode(""), setStartDate("") }} className="btn btn-primary d-block" style={{padding:"13px"}}> 
                      Clear
                    </button>
                </div>

                
              </div>

              {LinkView && selectName == "list" ? (
                <div className="mb-3 col-md-12">
                  <div className="text-end  ">
                    <button onClick={() => setSideModel(!sideModel)} className="btn btn-primary m-auto ms-3 ">
                      View Link(s)
                    </button>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                          setSelectName("clients");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          <b>Partner</b>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                          setSelectName("create");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          <b>Create Link</b>
                        </span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3");
                          setSelectName("list");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          <b>Link List</b>
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={customActiveTab} className="py-4 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12" className="mb-4">
                          <h5 className="mb-0 text-gray font-weight-700 font-size-16">Partner</h5>
                        </Col>
                        <Col sm="12">
                          <Client isOpen={showModal} api={api} selectName={selectName} toggle={setShowModal} />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12" className="mb-4">
                          <h5 className="mb-0 text-gray font-weight-700 font-size-16">Create Link</h5>
                        </Col>
                        <Col sm="12">
                          <Createlink getData={handleGetData} selectName={selectName} />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12" className="mb-4">
                          <h5 className="mb-0 text-gray font-weight-700 font-size-16">Link List</h5>
                        </Col>
                        <Col sm="12" className="mb-4">
                          <LinkList isOpen={sideModel} couponCode={couponCode} startDate={startDate} selectName={selectName}  toggle={setSideModel} getLinks={getLinks} />
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Deeplink;
