import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, CardBody, Col, Container, Label, Nav,Modal, ModalBody, ModalFooter,  NavItem, NavLink, Row, TabContent, TabPane, Spinner } from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";
import { showAlert } from "components/Common/AlertMessage";
import { LinkListAPI, PromoDownloadAPI } from "service/notifactionService";

const LinkList = (props) => {

  const { isOpen, toggle, selectName, startDate, couponCode } = props;



  useEffect(() => {
    if(selectName == "list"){
      ApiMainFunction(1);
    }

  },[selectName]);

  useEffect(() => {
      ApiMainFunction(1);

  },[startDate]);

  useEffect(() => {
    ApiMainFunction(1);

},[couponCode]);

  const [LinkView, setLinkView] = useState(false)

    const [data, setData] = useState([]);


    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [startPage, setStartPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [loadingModel, setLoadingModel] = useState(false)
    const [page, setPage] = useState("1");

    useEffect(() => {
        ApiMainFunction(1);
        
      }, []);
    
      useEffect(() => {
        if (currentPage >= 1) {
          ApiMainFunction(currentPage);
        }
      }, [currentPage]);
    
      const ApiMainFunction = async (pageNo,) => {

        props.getLinks(false)

        setLoading(true);
        let id = couponCode ? couponCode : "";
        let time = startDate != "" ? moment(startDate).format("YYYY-MM-DD") : "";
       
        const resp = await LinkListAPI(id, time, pageNo);
        if (resp.code == 200) {
          const modifiedData = resp.data.map(item => ({ ...item, check: false }));
          setLoading(false);
          setData(modifiedData);
          setTotalPages(resp.totalPage);
        } else {
          setData("");
          setLoading(false);
        }
      };
      const [selectedOrder, setSelectedOrder] = useState(null);



      // const handleSelectOrder = (selectedRowIndex) => {
      //   setSelectedOrder(config_id);
      //   props.getLinks(true)

        
      // };

      const handleSelectOrder = (selectedRowIndex, config_id) => {
        setData(prevData => prevData.map((item, index) => ({
          ...item,
          check: index === selectedRowIndex
        })));
        setSelectedOrder(config_id);
        props.getLinks(true)
      };


      const [sideData, setSideData] = useState([])


      useEffect(() =>{
        if(isOpen){
          viewListApi(selectedOrder, false)
        }

      },[isOpen])


      const viewListApi = async (id, down) => {
        setLoadingModel(true);
        try {
          const resp = await PromoDownloadAPI(id, down);
          if (resp.code == 200) {
            setLoadingModel(false);
            const transformedData = resp.data.map(item => ({
              ...item,
              Status: getStatusName(item.Status),
            }));
            setSideData(transformedData);
    
          } else {
            setLoadingModel(false);
            setSideData([]);
          }
        } catch (error) {
          // console.error("Error:", error);
          setLoadingModel(false);
        }
      }

      useEffect(() => {

      },[selectedOrder])


      const EcommerceOrderColumns = (selectedOrders, onSelectOrder) => [

        {
          dataField: 'select',
          text: '',
          formatter: (cellContent, row, rowIndex) => (
            <>
            <input
            type="radio"
            className="cursor-pointer "
            name="selectOrder"
            checked={row.check}
            onChange={() => onSelectOrder(rowIndex, row.config_id)}
          />


        </>
          ),
        },

//         <div key={row.config_id} onClick={() => onSelectOrder(row.config_id)}>
// {selectedOrder == row.config_id ? 'Selected' : 'Not Selected'}
//           {/* <img
//           src={selectedOrder == row.config_id ? 'path/to/on-image.png' : 'path/to/off-image.png'}
//           alt={selectedOrder == row.config_id ? 'Selected' : 'Not Selected'}
//           style={{ cursor: 'pointer' }}
//         /> */}
//         </div>
        {
          dataField: "time",
          text: "Generated Date",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="text-gray">{moment(row.time).format('MM-DD-YYYY')}</p>
            </>
          ),
        },
        {
          dataField: "partner_name",
          text: "Client Name",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="text-gray">{row.partner_name}</p>
            </>
          ),
        },
        {
          dataField: "config_id",
          text: "Transaction ID",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="text-gray">{row.config_id}</p>
            </>
          ),
        },
        {
          dataField: "credit_amount",
          text: "Credit(s)",
          formatter: (cellContent, row) => {
            return (
              <>
                <p className="text-gray">
                {row.credit_amount}
                </p>
              </>
            );
          },
        },
        {
          dataField: "max_usage",
          text: "Link Usage Limit",
          formatter: (cellContent, row) => {
            return (
              <>
                <p className="text-gray">
                {row.max_usage}
                </p>
              </>
            );
          },
        },
        {
          dataField: "quantity",
          text: "Qty.",
          
          formatter: (cellContent, row, index) => (
            <>
            <p className="text-gray">{row.quantity}</p>
          </>
          ),
        },

        {
          dataField: "used_quantity",
          text: "Used Qty.",
          
          formatter: (cellContent, row, index) => (
            <>
            <p className="text-gray">{row.used_quantity}</p>
          </>
          ),
        },
        {
          dataField: "expiry",
          text: "Expiry Date",
          sort: true,
          formatter: (cellContent, row) => {
            return (
              <>
                <p className="text-gray">
                {moment(row.expiry).format('MM-DD-YYYY')}
                </p>
              </>
            );
          },
        },
        
        //added by (Nehal Vasoya)
      ];

      const isString = (value) => {
        return typeof value === 'string';
      };


      const ModelTable = () => [

        {
          dataField: 'Link',
          text: 'Links',
          formatter: (cellContent, row) => (
            <>
            <p className="text-gray" style={{width:"410px", whiteSpace:"break-spaces",}}>{row.Link}</p>
            </>
          ),
        },
        {
          dataField: "Status",
          text: "Status",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <p className="text-gray">{row.Status}</p>
            </>
          ),
        },
        {
          dataField: "UserIDs",
          text: "User IDs",
          formatter: (cellContent, row) => (
            <>
              <p className="text-gray">{row.UserIDs.join(", ")}</p>
            </>
          ),
        },
        
      ];

      // const handleDownload = () => {
      //   viewListApi(selectedOrder, true)
      // }

      const getStatusName = (statusNumber) => {
        const statusMap = {
          1: 'active',
          2: 'claimed',
          3: 'expired',
        };
      
        return statusMap[statusNumber] || null; // Return null or a default value if the status number is not found
      };


      const handleDownload = () => {
        // Convert JSON object to a string
        const jsonString = JSON.stringify(sideData , null, 2);
    
        // Create a blob with the JSON string and type
        const blob = new Blob([jsonString], { type: 'application/json' });
    
        // Create a link element
        const link = document.createElement('a');
    
        // Create a URL for the blob
        const url = URL.createObjectURL(blob);
    
        // Set the download attribute with a filename
        link.download = 'data.json';
        link.href = url;
    
        // Programmatically click the link to trigger the download
        link.click();
    
        // Clean up the URL object
        URL.revokeObjectURL(url);
      };


      const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
      };
    
      const handleNextPage = () => {
        if (currentPage < totalPages) {
          setCurrentPage((prevPage) => prevPage + 1);
          if (currentPage >= startPage + 4 && currentPage + 1 <= totalPages) {
            setStartPage((prevStartPage) => prevStartPage + 1);
          }
        }
      };
    
      const handleLastPage = () => {
        setCurrentPage(totalPages);
        setStartPage(totalPages > 4 ? totalPages - 4 : 1);
      };
    
      const handlePreviousPage = () => {
        if (currentPage > 1) {
          setCurrentPage((prevPage) => prevPage - 1);
          if (currentPage - 1 < startPage) {
            setStartPage((prevStartPage) => prevStartPage - 1);
          }
        }
      };
    
      const handlePreviousLastPage = () => {
        setCurrentPage(1);
        setStartPage(1);
      };
    
      const endPage = Math.min(startPage + 4, totalPages);
  return (
    <>  
    <React.Fragment>
        <Card>
          <CardBody>
            <>
              {!loading ? (
                <>


{/* {isString(selectedOrder)
          ? `selectedOrder is a string: ${selectedOrder}`
          : `selectedOrder is not a string: ${selectedOrder}`} */}

                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive orders-table-main-div" id="harshu">
                          <BootstrapTable keyField="config_id"  rowEvents={{
        onClick: (e, row, rowIndex) => {
          // handleSelectOrder(row.config_id);
          handleSelectOrder(rowIndex, row.config_id);
        },
      }}   data={data} columns={EcommerceOrderColumns(selectedOrder, handleSelectOrder)} responsive bordered={false} striped={false} classes={"table align-middle table-nowrap table-no-check table-check"} headerWrapperClasses={"table-light"} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                  {totalPages >= 1 ? (
                    data && data.length > 0 ? (
                      <div className="pagination pagination-rounded justify-content-center mt-4">
                        <button onClick={handlePreviousLastPage} disabled={currentPage === 1} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                          {"<<"}
                        </button>
                        <button onClick={handlePreviousPage} disabled={currentPage === 1} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                          {"<"}
                        </button>
                        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map((page) => (
                          <button key={page} onClick={() => handlePageClick(page)} disabled={currentPage === page} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn btn-light  btn-xl rounded-circle m-2"}>
                            {page}
                          </button>
                        ))}
                        {totalPages > startPage + 4}
                        <button onClick={handleNextPage} disabled={currentPage === totalPages} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                          {">"}
                        </button>
                        <button onClick={handleLastPage} disabled={currentPage === totalPages} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                          {">>"}
                        </button>
                      </div>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <p> No Data Found</p>{" "}
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // marginTop: checkmargin,
                  }}
                >
                  <Spinner style={{ width: "2rem", height: "2rem" }} children={false} />
                </div>
              )}
            </>
          </CardBody>
        </Card>
      </React.Fragment>



      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal modal-lg"
        tabIndex="-1"
        toggle={() => {
          toggle(false);
        }}
      >

        
        <div className="modal-content">
          {/* <ModalHeader style={{alignItems:"inherit"}} toggle={() => toggle(false)} >{"dgfsdfgdsg"}</ModalHeader> */}
          <ModalBody>
            <div className=" serviceShowMedia">
              <div className="row">
                <div className="col-lg-12">
                  <h4 className="text-center text-black">View Links</h4>
                </div>

                {!loadingModel ? (

                <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive orders-table-main-div" style={{height:"400px",}} id="harshu">
                          <BootstrapTable keyField="id" data={sideData} columns={ModelTable()} responsive bordered={false} striped={false} classes={"table align-middle table-nowrap table-no-check table-check"} headerWrapperClasses={"table-light"} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : (
                  <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // marginTop: checkmargin,
                  }}
                >
                  <Spinner style={{ width: "2rem", height: "2rem" }} children={false} />
                </div>
                )}



              </div>
            </div>
          </ModalBody>
          <ModalFooter className="text-center justify-content-center">
           
            <button type="button" className="btn btn-primary" onClick={() => handleDownload()}>
              Download
             </button>
          </ModalFooter>
        </div>
      </Modal>
      
    </>
  )
}


LinkList.propTypes = {
    orders: PropTypes.array,
    onGetOrders: PropTypes.func,
  };
  
  export default withRouter(LinkList);
