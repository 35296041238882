import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Badge,
} from "reactstrap";
import { getLumenChat } from "service/orderService";
import lumenAvatar from "../../assets/images/profileicon.png";
import MarkdownRenderer from "./markedtextconvert";
const ChatMessage = ({ message, isUser }) => {
  
  return (
    <div className={`chat-message ${isUser ? 'user-message' : 'ai-message'}`}>
      {!isUser && (
        <div className="ai-avatar">
          <img 
            // src={lumenAvatar} 
            // alt="Lumen" 
            // width="32" 
            // height="32" 
            // style={{
            //   borderRadius: '50%',
            //   marginRight: '8px',
            //   backgroundColor: '#F6F6F6'
            //   }}
              />
        </div>
      )}
      <div className="message-content">
        { isUser ? message :  <MarkdownRenderer markdown={message} /> }
        {/* {message} */}
      </div>
    </div>
  );
};

const OrdersDetailss = () => {
  const { id } = useParams();
  const [orderDetail, setOrderdetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  
  useEffect(() => {
    if (id) {
      getchat();
    } 
  }, [id]);

  const getchat = async () => {
    try {
      setLoading(true);
      const resp = await getLumenChat(id);

      if (resp && resp.code === 200 && resp.chat && resp.chat.messages) {
        // Transform the chat messages from the API response
        const formattedMessages = resp.chat.messages.map(msg => ({
          text: msg.content,
          isUser: msg.role === 'user'
        }));
        
        setMessages(formattedMessages);
      } else {
        // Handle empty or invalid response
        setMessages([]);
      }
    } catch (error) {
      console.error("Error fetching chat:", error);
      setMessages([]);
    } finally {
      // This ensures loading is set to false in all cases
      setLoading(false);
    }
  };

 
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Order Details | BrightCheck</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <CardBody className="p-0">
              <div className="chat-container">
                <div className="chat-messages">
                  <div>

                  </div>
                  {loading ? (
                    <div className="text-center p-4">
                      <Spinner color="primary" />
                    </div>
                  ) : messages.length === 0 ? (
                    <div className="text-center p-4">
                      <h2 className="text-muted mb-0">No chat messages found</h2>
                    </div>
                  ) : (
                    messages.map((msg, index) => (
                      <ChatMessage 
                        key={index}
                        message={msg.text}
                        isUser={msg.isUser}
                      />
                    ))
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default OrdersDetailss;
