import React, { useState, useEffect, useRef  } from "react";
import PropTypes, { element } from "prop-types";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Input, Label, Spinner } from "reactstrap";
import moment from "moment";
import Select from "react-select";
import { selectClientApi, CreateLinkAPI } from "service/notifactionService";
import { showAlert } from "components/Common/AlertMessage";

const Createlink = (props) => {

  const {selectName} = props;
  const datePickerRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [AmountCredit, setAmountCredit] = useState("");
  // const [AmountErr, setAmountErr] = useState("");
  const [Quantity, setQuantity] = useState("");
  const [QueErr, setQueErr] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [startDateError, setStartDateErros] = useState("");
  const [linkUsage, setLinkUsage] = useState("1");
  const [linkErr, setLinkErr] = useState("");
  const [option, setOption] = useState([]);
  const [type, setType] = useState("");


  useEffect(() => {
    ApiCall();
  }, []);


  useEffect(() => {
    if(selectName == "create"){
      ApiCall();
    }

  },[selectName]);

  const ApiCall = async (name) => {
    // setLoading(true);
    try {
      const resp = await selectClientApi(name);
      if (resp.code === 200) {
        setLoading(false);
        setOption(resp.data);
      } else {
        setLoading(false);
        setOption([]);
      }
    } catch (error) {
      // console.error("Error:", error);
      setLoading(false);
      setOption([]);
    }
  };

  const handleQuantity = (e) => {
    const value = e.target.value;

    // Allow only numbers and restrict the value to 1-999, disallow leading zeros
    if (value === "" || (value.match(/^[1-9][0-9]{0,2}$/) && Number(value) <= 999)) {
      setQuantity(value);
      setQueErr("");
    } else {
      setQueErr("Please enter only numbers less than 1000");
    }
  };

  const handleLinkUsage = (e) => {
    const value = e.target.value;

    // Allow only numbers and restrict the value to 1-99, disallow leading zeros
    if (value === "" || (value.match(/^[1-9][0-9]{0,2}$/) && Number(value) <= 99)) {
      setLinkUsage(value);
      setLinkErr("");
    } else {
      setLinkErr("Please enter only numbers less than 100");
    }
  };

  const [clientSelect, setClientSelect] = useState(null);

  const [clientErr, setClientErr] = useState("");

  const handleCreate = () => {

    if (clientSelect) {
      setClientErr("");
    } else {
      setClientErr("Please select partner");
    }

    if (AmountCredit) {
      setClientErr("");
    } else {
      setClientErr("Please select partner");
    }

    if (Quantity) {
      setQueErr("");
    } else {
      setQueErr("Please enter link quantity");
    }

    if (startDate) {
      setStartDateErros("");
    } else {
      setStartDateErros("Please select link expiry date");
    }

    if (linkUsage) {
      setLinkErr("");
    } else {
      setLinkErr("Please enter link usage limit");
    }

    if (clientSelect && AmountCredit && Quantity && Quantity >= 1 && linkUsage && linkUsage >= 1 && startDate && type) {
      CreateApi()
    } 
  };


  const convertToISO = (dateString) => {
    if(dateString){
      const date = new Date(dateString);
    return date.toISOString();
    }
  }

  const CreateApi = async () => {

    let time = startDate != "" ? moment(startDate).format("YYYY-MM-DD") : "";
    let isoString = ""
    if(time != ""){
       isoString = convertToISO(time ? time : "");
    }

    let id = type && type.all.partner_id;
    let data = {
      "link_quantity": parseInt(Quantity),
      "with_link": true,
      "configurations": {
          "max_usage": parseInt(linkUsage),
          "expiry": isoString,
      }
  }
    setLoading(true);
    try {
      const resp = await CreateLinkAPI(data, id);
      if (resp.code === 200) {
        setLoading(false);
        showAlert("success", resp?.data?.message);
        props.getData("list", "3")
        handleCancle()

      } else {
        setLoading(false);
        showAlert("error", resp?.data?.message);
      }
    } catch (error) {
      // console.error("Error:", error);
      setLoading(false);
    }
  }

  const handleCancle = () => {
    setClientSelect(null)
    setType("")
    setAmountCredit("");
    setQuantity("");
    setStartDate(null);
    setLinkUsage("");

    setClientErr("")
    setQueErr("")
    setLinkErr("")
    setStartDateErros("")
    
  };

  


  const handleselect = (selected) => {
    if (selected.value != "") {
      setType(selected);
      setAmountCredit(selected.all?.credit_amount);
      setClientSelect(selected.label);
      setClientErr("");
    } else {
      setType("");
      setClientSelect("");
      setAmountCredit("");
    }
  };

  const handleSearch = (inputValue) => {
    if (inputValue.length >= 3) {
      ApiCall(inputValue);
    }
  };

  return (
    <>
      {!loading ? (
        <>
          <div className="row">
            <div className="col-md-12">
              <label className="w-100 mb-1">Select Partner*</label>
              <Select id="selectCenter" options={option && option.length > 0 ? [{ label: "Partner Name", value: "" }].concat(option.map((e) => ({ label: e.name, value: e.name, all: e }))) : [{ label: "Partner Name", value: "" }]} isClearable={false} value={type} onInputChange={(e) => handleSearch(e)} onChange={(e) => handleselect(e)} placeholder={""} />

              <p className="textdanger"> {clientErr}</p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <label className="w-100 mb-1">
              Quantity Of Credit Per User</label>
              <div className="">
                <Input type="text" disabled={clientSelect} maxLength={3} value={AmountCredit} placeholder="" readOnly />
              </div>
            </div>
            <div className="col-md-6">
              <Label className="form-label mb-1">Link Quantity*</Label>
              <Input type="text" maxLength={3} value={Quantity} placeholder="" onChange={(e) => handleQuantity(e)} />
              <p className="textdanger"> {QueErr}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label className="w-100 mb-1">Link Expiry Date*</label>
              <div className="pickdiv">
                <DatePicker
                ref={datePickerRef}
                  className="date-div Coupon-Create-date"
                  name="formdate"
                  selected={startDate}
                  onChange={(e) => (setStartDateErros(""), setStartDate(e))}
                  dateFormat="MM/dd/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  minDate={moment().toDate()}
                  autoComplete="off"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  placeholderText=""
                />
                <i onClick={() => datePickerRef.current.setFocus()} className="fa fa-calendar"></i>
              </div>
              <p className="textdanger" > {startDateError}</p>
            </div>
            <div className="col-md-6">
              <div className="">
                <Label className="form-label mb-1">Link Usage Limit Per User*</Label>
                <Input type="text" maxLength={2} value={linkUsage} placeholder="" onChange={(e) => handleLinkUsage(e)} />
                <p className="textdanger"> {linkErr}</p>
              </div>
            </div>

            <div className="col-md-12 text-center">
              {loading ? (
                <button className="btn btn-primary btn-block" type="submit" disabled>
                  <Spinner size="sm">Loading...</Spinner>
                  <span> Loading</span>
                </button>
              ) : (
                <>
                  <button className="btn btn-primary btn-block" type="submit" onClick={handleCancle}>
                    Cancel
                  </button>

                  <button className="btn btn-primary btn-block ms-2" type="submit" onClick={handleCreate}>
                    Create
                  </button>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner style={{ width: "2rem", height: "2rem" }} children={false} />
        </div>
      )}
    </>
  );
};

Createlink.propTypes = {
  notifaction: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(Createlink);
