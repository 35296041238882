import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { Card, CardBody, Col, Row, Spinner, Button, Modal, ModalBody, ModalFooter, ModalHeader, Label, Input } from "reactstrap";
import { getClientDetailsApi, DeeplinkAddApi, DeeplinkEditApi } from "service/notifactionService";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EditIcon from "./../../assets/icons/edit-icon.svg";
import { showAlert } from "components/Common/AlertMessage";

const Client = (props) => {
  const { isOpen, toggle, selectName, api } = props;

  const [isopen, setOpen] = useState(isOpen);
  const datePickerRef = useRef(null);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startPage, setStartPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState("1");


  useEffect(() => {
  setApi(0)
  },[api]);

  useEffect(() => {
    if(selectName == "clients"){
      ApiMainFunction(1);
    }

  },[selectName]);

  useEffect(() => {
    ApiMainFunction(1);
  }, []);

  useEffect(() => {
    if (currentPage >= 1) {
      ApiMainFunction(currentPage);
    }
  }, [currentPage]);

  const ApiMainFunction = async (pageNo) => {
    setLoading(true);
    const resp = await getClientDetailsApi(pageNo);
    if (resp.code == 200) {
      setLoading(false);
      setData(resp.data);
      setTotalPages(resp.totalPage);
    } else {
      setData("");
      setLoading(false);
    }
  };

  const handleStatus = async (e, row) => {

    let data = {
      partner_id: row.partner_id,
      status: e.target.checked == true ? 1 : 2,
      expiry: row.expiry,
      credit_amount: row.credit_amount,
      quota: row.quota_limit,
    };

    setLoading(true);
    try {
      const resp = await DeeplinkEditApi(data);
      if (resp.code === 200) {
        setLoading(false);
        
        ApiMainFunction(1);
        showAlert("success", resp.message);
      } else {
        setLoading(false);
        showAlert("error", resp.message);
      }
    } catch (error) {
      // console.error("Error:", error);
      setLoading(false);
    }
  }

  const EcommerceOrderColumns = () => [
    {
      dataField: "name",
      text: "Partner Name",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="text-gray">{row.name}</p>
        </>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="text-gray text-center">{row.email != "" ? row.email : "-"}</p>
        </>
      ),
    },
    {
      dataField: "api_key",
      text: "Api Key",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="text-gray">{row.api_key}</p>
        </>
      ),
    },
    {
      dataField: "status",
      text: "STATUS",
      sort: true,
      formatter: (cellContent, row, index) => (
        <div className="float-start">
          {row.status == 3 ? (
                  <span
                    style={{
                      borderRadius: "38px",
                      padding: "7px 21px",
                      fontWeight: "bold",
                      backgroundColor: "#ff7373", // Red background for expired status
                      color: "#262626", // Dark text for readability
                      fontSize: "13px",
                    }}
                  >Expired
                  </span>
                ) : (
                  <div className="form-check d-flex justify-content-end form-switch ps31 form-switch-lg mb-3 Coupon-table-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`customSwitchsizelgs${index}`}
                      checked={row.status == 1}
                      onChange={(e) => handleStatus(e, row)}
                    />
                    <label
                      className={row.status == 1 ? "form-check-label checked" : "form-check-label"}
                      htmlFor={`customSwitchsizelgs${index}`}
                    >
                      {row.status == 1 ? "Active" : "Inactive"}
                    </label>
                  </div>
                )}
        </div>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            {/* <p className="text-gray" onClick={() => handleAction(row)}>
              gfsgwr
            </p> */}
{row.status == 3 ? (
                  <span> - </span>
                ) :
            <img  onClick={() => handleAction(row)} title={"Edit"} src={EditIcon} alt="edit icon" style={{cursor:"pointer",}} /> }
          </>
        );
      },
    },
    //added by (Nehal Vasoya)
  ];

  const [row, setRow] = useState("");

  const handleAction = (row) => {
    setName(row.name);
    setEmail(row.email);
    setCredit(row.credit_amount);
    setQuota(row.quota_limit);

    const datePart = row.expiry.split('T')[0];
    const parsedDate = moment(datePart).toDate();
    setStartDate(parsedDate);
    setOpen(!isOpen);
    setApi(1);
    setRow(row);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      if (currentPage >= startPage + 4 && currentPage + 1 <= totalPages) {
        setStartPage((prevStartPage) => prevStartPage + 1);
      }
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setStartPage(totalPages > 4 ? totalPages - 4 : 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      if (currentPage - 1 < startPage) {
        setStartPage((prevStartPage) => prevStartPage - 1);
      }
    }
  };

  const handlePreviousLastPage = () => {
    setCurrentPage(1);
    setStartPage(1);
  };

  const endPage = Math.min(startPage + 4, totalPages);

  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [startDate, setStartDate] = useState(null);


  const [startDateError, setStartDateErros] = useState("");
  const [quota, setQuota] = useState("");
  const [QuoErr, setQuoErr] = useState("");
  const [Api, setApi] = useState(0);

  const handleName = (e) => {
    const result = /^[A-Za-z]( ?[A-Za-z] ?)*$/;
    if (e.target.value === "" || result.test(e.target.value)) {
      setName(e.target.value);
      if (e.target.value != "") {
        setNameErr("");
      } else {
        setNameErr("Please enter partner name");
      }
    }
  };

  function isValidEmail(email) {
    return /^([\w-\.]+@([\w-]+\.)+[\w-]{2,3})?$/.test(email);
  }

  const handleEmail = (e) => {
    setEmail(e);

    if (e) {
      if (!isValidEmail(e)) {
        setEmailError("Please enter valid email");
      } else {
        setEmailError("");
      }
    } else {
      setEmailError("");
    }
  };

  const [credit, setCredit] = useState("");
  const [creditErr, setCreditErr] = useState("");

  const handleCredit = (e) => {
    const value = e.target.value;

    // Allow only numbers and restrict the value to 1-999, disallow leading zeros
    if (value === "" || (value.match(/^[1-9][0-9]{0,2}$/) && Number(value) <= 9)) {
      setCredit(value);
      setCreditErr("");
    } else {
      setCreditErr("Please enter only numbers less than 10");
    }
  };

  const handleQuota = (e) => {
    const value = e.target.value;

    
    // Allow only numbers and restrict the value to 1-999, disallow leading zeros
    if (value === "" || (value.match(/^[1-9][0-9]{0,2}$/) && Number(value) <= 999)) {
      setQuota(value);
      setQuoErr("");
    } else {
      setQuoErr("Please enter only numbers less than 1000");
    }
  };

  const convertToISO = (dateString) => {
    if(dateString){
      const date = new Date(dateString);
    return date.toISOString();
    }
  }

  const addEditApiCall = async () => {

    let time = startDate != "" ? moment(startDate).format("YYYY-MM-DD") : "";


    let isoString = ""
  if(time != ""){
     isoString = convertToISO(time ? time : "");
  }


    if (Api == 1) {
    let  data = {
        partner_id: row.partner_id,
        status: row.status,
        email: email,
        expiry: isoString,
        credit_amount: parseInt(credit),
        quota_limit: quota ? parseInt(quota) : "",
      };

      setLoading(true);
      try {
        const resp = await DeeplinkEditApi(data);
        if (resp.code === 200) {
          setLoading(false);
          
          ApiMainFunction(1);
          setName("");
          setEmail("");
          setCredit("");
          setQuota("");
          setStartDate(null)
          showAlert("success", resp.message);
        } else {
          if(resp?.code != 503){
            showAlert("error", resp?.data?.message);
          }else{
            showAlert("error","Something went wrong. Please try again later.");
          }
          setLoading(false);
          handleCancle()
        }
      } catch (error) {
        // console.error("Error:", error);
        setLoading(false);
      }
    } else {
    let  data = {
        name: name,
        email: email,
        expiry: isoString,
        credit_amount: parseInt(credit),
        quota_limit: quota ? parseInt(quota) : "",
      };

      setLoading(true);
      try {
        const resp = await DeeplinkAddApi(data);
        if (resp.code === 200) {
          setLoading(false);
          ApiMainFunction(1);
          setName("");
          setEmail("");
          setCredit("");
          setQuota("");
          setStartDate(null)
          showAlert("success", resp.message);
        } else {
          if(resp?.code != 503){
            showAlert("error", resp?.data?.message);
          }else{
            showAlert("error","Something went wrong. Please try again later.");
          }
          handleCancle()
          setLoading(false);
        }
      } catch (error) {
        // console.error("Error:", error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if(Api == 0){
      setName("");
    setEmail("");
    setCredit("");
    setQuota("");
    setStartDate(null)
    }

  },[Api])
  

  const handleCancle = () => {
    toggle(false);
    setOpen(false);
    setApi(0)
    setNameErr("");
    setEmailError("");
    setStartDateErros("");
    setQuoErr("");
    setCreditErr("")
    setName("");
    setEmail("")
    setStartDate(null);
    setQuota("")
    setCredit("")
  };

  const handleClient = (e) => {
    e.preventDefault();




    let emialReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,3})?$/;

    if (name) {
      setNameErr("");
    } else {
      setNameErr("Please enter partner name");
    }

    if (email != "") {
      if (emialReg.test(email)) {
        setEmailError("");
      } else {
        setEmailError("Please enter valid email");
      }
    } else {
      setEmailError("");
    }

    if(credit){
      setCreditErr("")
    }else{
      setCreditErr("Please enter quantity of credit per user");
    }

    if (quota) {
      setQuoErr("");
    } else {
      setQuoErr("");
    }

    if (startDate) {
      setStartDateErros("");
    } else {
      setStartDateErros("Please enter API key expiry date");
    }

    if (name && name.length >= 1 && (!email || (email && emialReg.test(email))) && startDate && credit && credit >= 1) {
      toggle(false);
      setOpen(false);
      addEditApiCall();
    }
  };

  return (
    <>
      <React.Fragment>
        <Card>
          <CardBody>
            <>
              {!loading ? (
                <>
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive orders-table-main-div" id="harshu">
                          <BootstrapTable keyField="id" data={data} columns={EcommerceOrderColumns()} responsive bordered={false} striped={false} classes={"table align-middle table-nowrap table-no-check table-check"} headerWrapperClasses={"table-light"} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                  {totalPages >= 1 ? (
                    data && data.length > 0 ? (
                      <div className="pagination pagination-rounded justify-content-center mt-4">
                        <button onClick={handlePreviousLastPage} disabled={currentPage === 1} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                          {"<<"}
                        </button>
                        <button onClick={handlePreviousPage} disabled={currentPage === 1} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                          {"<"}
                        </button>
                        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map((page) => (
                          <button key={page} onClick={() => handlePageClick(page)} disabled={currentPage === page} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn btn-light  btn-xl rounded-circle m-2"}>
                            {page}
                          </button>
                        ))}
                        {totalPages > startPage + 4}
                        <button onClick={handleNextPage} disabled={currentPage === totalPages} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                          {">"}
                        </button>
                        <button onClick={handleLastPage} disabled={currentPage === totalPages} className={currentPage === page ? "test btn btn-primary btn-circle btn-xl rounded-circle m-2" : "test btn  btn-circle btn-xl rounded-circle m-2"}>
                          {">>"}
                        </button>
                      </div>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <p> No Data Found</p>{" "}
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // marginTop: checkmargin,
                  }}
                >
                  <Spinner style={{ width: "2rem", height: "2rem" }} children={false} />
                </div>
              )}
            </>
          </CardBody>
        </Card>
      </React.Fragment>

      <Modal
        isOpen={isOpen ? isOpen : isopen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal modal-lg"
        tabIndex="-1"
        toggle={() => {
          toggle(false);
          setOpen(false);
        }}
        backdrop="static"
  keyboard={true}
      >
        <div className="modal-content">
          {/* <ModalHeader style={{alignItems:"inherit"}} toggle={() => toggle(false)} >{"dgfsdfgdsg"}</ModalHeader> */}
          <ModalBody>
            <div className=" serviceShowMedia">
              <div className="row">
                <div className="col-lg-12">
                  <h4 className="text-center text-black">{Api == 1 ? "Edit" : "Add"} Partner</h4>
                </div>
                <div className="col-lg-12">
                  <label className="w-100 mb-1">Partner Name*</label>

                  <Input type="text" disabled={Api == 1 ? true :false} maxLength={30} value={name} placeholder="" onChange={(e) => handleName(e)} />
                  <p className="textdanger"> {nameErr}</p>
                </div>

                <div className="col-lg-12">
                  <label className="w-100 mb-1">Email</label>

                  <Input type="text" maxLength={80} value={email} placeholder="" onChange={(e) => handleEmail(e.target.value.trim())} />
                  <p className="textdanger"> {emailError}</p>
                </div>

                <div className="col-lg-12">
                  <label className="w-100 mb-1">
                  Quantity Of Credit Per User*</label>

                  <Input type="text" maxLength={1} value={credit} placeholder="" onChange={(e) => handleCredit(e)} />
                  <p className="textdanger"> {creditErr}</p>
                </div>

                <div className="col-md-6">
                  <label className="w-100 mb-1"  >
                    Api Key Expiry Date*</label>
                    <div className="pickdiv">
                      <DatePicker
                      ref={datePickerRef}
                        className="date-div Coupon-Create-date"
                        name="formdate"
                        selected={startDate}
                        onChange={(e) => (setStartDateErros(""), setStartDate(e))}
                        dateFormat="MM/dd/yyyy"
                        showMonthDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        minDate={moment().toDate()}
                        autoComplete="off"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        placeholderText=""
                      />
                      <i onClick={() => datePickerRef.current.setFocus()} className="fa fa-calendar"></i>
                    </div>
                    <p className="textdanger"> {startDateError}</p>
                </div>
                <div className="col-lg-6">
                  <label className="w-100 mb-1">Daily Quota Limit</label>

                  <Input type="text" maxLength={3} value={quota} placeholder="" onChange={(e) => handleQuota(e)} />
                  <p className="textdanger"> {QuoErr}</p>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="text-center justify-content-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                handleCancle();
              }}
            >
              Cancel
            </button>
            <button type="button" className="btn btn-primary" onClick={(e) => handleClient(e)}>
              {Api == 1 ? "Edit" : "Add"} Partner
            </button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
};

Client.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(Client);
