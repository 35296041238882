import { Breadcrumbs } from "@mantine/core";
import moment from "moment";
import Client from "pages/Deeplink/Client";
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Feedback/index.css";
import good from "../../assets/images/good.svg";
import bad from "../../assets/images/bad.svg";
import ReactInputMask from "react-input-mask";

// import {
//   Card,
//   CardBody,
//   Col,
//   Container,
//   Label,
//   Nav,
//   NavItem,
//   NavLink,
//   Row,
//   TabContent,
//   TabPane,
//   Spinner,
// } from "reactstrap";
import { MetaTags } from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { FeedbackGetAPI } from "service/notifactionService";
import FeedbackDetail from "./FeedbackDetail";
import './index.css';
const Feedback = () => {
  const [links, setLinks] = useState("");
  const [api, setApi] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [startDateFrom, setStartDateFrom] = useState("");
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [showModal, setShowModal] = useState(false);


  const [debtType, setDebtType] = useState("");
  const [debtTypeErr, setDebtTypeErr] = useState("");
  const [debtTypeFlag, setdebtTypeFlag] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let time = startDate != "" ? moment(startDate).format("MM-DD-YYYY") : "";
         let time2 =
      startDateFrom != "" ? moment(startDateFrom).format("MM-DD-YYYY") : "";
        const data = { limit: 10, page: 1, deviceType : debtType ,from: time, to: time2}; // Replace with the actual data you need to send
        const resp = await FeedbackGetAPI(data);
        if (resp.code === 200) {
          setLinks(resp);
        } else {
          setLinks("");
        }
      } catch (error) {
        // console.error("Error:", error);
      }
    };

    fetchData();
  }, [debtType , startDate , startDateFrom]);



  const handleDebtType = (e) => {
    let data = e.target.value;
    setDebtType(data);

    if (data) {
      setDebtTypeErr("");
      setdebtTypeFlag(false);
    } else {
      setdebtTypeFlag(true);
      setDebtTypeErr("Please select the type of device");
    }
  };
  const [feedbacks1, setFeebacks1] = useState("");
  const [feedbacks2, setFeebacks2] = useState("");
  useEffect(() => {
    let isMounted = true;

    const qualityFeedback = links?.feedbacksCount?.filter((e) =>
      e?.question_text.includes("quality of your app")
    );

    const qualityFeedback2 = links?.feedbacksCount?.filter((e) =>
      e?.question_text.includes("Check Report Useful")
    );

    // if (isMounted) {
      setFeebacks1(qualityFeedback);
      setFeebacks2(qualityFeedback2);
    // }

    return () => {
      isMounted = false; // Clean up by setting isMounted to false when the component unmounts
    };
  }, [links]);


  // const QualityCheck = links?.feedbacksCount?.filter((e) => {
  //   console.log(e.question_text.includes("quality of your app"), "ee...");
  // });


  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title> Feedback | BrightCheck</title>
        </MetaTags>

        <Row>
          <Col xl="12">
            <Row>
              <Col md={"4"}>
                <Card className="mini-stats-wid home-card23">
                  <CardBody>
                    <div className="d-flex align-items-center mb-3">
                      <p className="text-muted fw-medium mb-0  fw-bold">
                        Total Feedback
                      </p>
                    </div>
                    <div className="flex-grow-1">
                      
                      <h1 className="mb-0">{links && links?.totalFeedback}</h1>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              {/* {links &&
                links?.feedbacksCount.map((e) => (
                  <> */}
              <Col md={"4"}>
                <Card className="mini-stats-wid home-card23">
                  <CardBody>
                    <div className="d-flex align-items-center mb-3">
                      <p className="text-muted fw-medium mb-0  fw-bold">
                        App Quality
                      </p>
                    </div>
                    <div className="d-flex">
                      <h1 className="mb-0">
                        <img src={good} className="me-1" />

                        
                        {(feedbacks1 && feedbacks1[0]?.goodCount == "") ||
                        (feedbacks1 && feedbacks1[0]?.goodCount == null)
                          ? "-"
                          : feedbacks1 && feedbacks1[0]?.goodCount}
                      </h1>
                      <h1 className="mb-0 ms-5">
                        <img src={bad} className="me-1" />
                        {feedbacks1 && feedbacks1[0]?.badCount}
                      </h1>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {/* </>
                ))} */}

              <Col md={"4"}>
                <Card className="mini-stats-wid home-card23">
                  <CardBody>
                    <div className="d-flex align-items-center mb-3">
                      <p className="text-muted fw-medium mb-0  fw-bold">
                        Checks Useful
                      </p>
                    </div>

                    <div className="d-flex">
                      <h1 className="mb-0">
                        <img src={good} className="me-1" />
                        {feedbacks2 && feedbacks2[0]?.goodCount}
                      </h1>
                      <h1 className="mb-0 ms-5">
                        <img src={bad} className="me-1" />
                        {feedbacks2 && feedbacks2[0]?.badCount}
                      </h1>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <Breadcrumbs breadcrumbItem="Notification Settings" /> */}

        <div className="row">
          <div className="col-2 mb-3">
            <Label className="form-label">From</Label>
            <DatePicker
              className="form-control"
              selected={startDate}
              onChange={(e) => setStartDate(e)}
              dateFormat="MM/dd/yyyy"
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              minDate={moment("01/01/1947").toDate()}
              maxDate={moment(startDateFrom).toDate()}
              autoComplete="off"
              placeholderText="MM/DD/YYYY"
              customInput={<ReactInputMask mask="99/99/9999" />}
            />
          </div>

          <div className="col-2 mb-3">
            <Label className="form-label">To</Label>
            <DatePicker
              className="form-control "
              selected={startDateFrom}
              onChange={(e) => setStartDateFrom(e)}
              dateFormat="MM/dd/yyyy"
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              minDate={moment(startDate).toDate()}
              autoComplete="off"
              placeholderText="MM/DD/YYYY"
              customInput={<ReactInputMask mask="99/99/9999" />}
            />
          </div>

          <div className="col-2 mb-3">
            <label htmlFor="input-rounded1" className="form-label">
              Device
            </label>
            <select
              value={debtType}
              className="form-select"
              style={{ height: "48px" }}
              id="inlineFormSelectPref"
              onChange={handleDebtType}
            >
              <option value="">Choose...</option>
              <option value="iOS">iOS</option>
              <option value="Android">Android</option>
            </select>
            {/* {debtTypeErr && <p style={{ color: "#bc2a2a" }}>{debtTypeErr}</p>} */}
          </div>

          <div className="col-4 mb-3">
            <Label className="form-label">&nbsp;</Label>
            <button
              className="btn btn-primary d-block"
              style={{ padding: "13px" }}
              onClick={() => {
                setStartDate("");
                setStartDateFrom("");
                setDebtType("");
                  window.location.reload()
              }}
            >
              Clear
            </button>
          </div>
        </div>

        <FeedbackDetail
          startDate={startDate}
          startDateFrom={startDateFrom}
          debtType={debtType}
        />
      </div>
    </>
  );
};

export default Feedback;
