import React from 'react';

const MarkdownRenderer = ({ markdown }) => {
  // Function to parse inline markdown (bold, italics, links, etc.)
  const parseInlineMarkdown = (text) => {
    // Bold: **text** or __text__
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    text = text.replace(/__(.*?)__/g, '<strong>$1</strong>');

    // Italics: *text* or _text_
    text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
    text = text.replace(/_(.*?)_/g, '<em>$1</em>');

    // Links: [text](url)
    text = text.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>');

    // Inline code: `code`
    text = text.replace(/`(.*?)`/g, '<code>$1</code>');

    return text;
  };

  // Function to render block-level markdown (headings, lists, tables, etc.)
  const renderBlockMarkdown = (block) => {
    if (block.startsWith('# ')) {
      return <h1 dangerouslySetInnerHTML={{ __html: parseInlineMarkdown(block.replace('# ', '')) }} />;
    } else if (block.startsWith('## ')) {
      return <h2 dangerouslySetInnerHTML={{ __html: parseInlineMarkdown(block.replace('## ', '')) }} />;
    } else if (block.startsWith('### ')) {
      return <h3 dangerouslySetInnerHTML={{ __html: parseInlineMarkdown(block.replace('### ', '')) }} />;
    } else if (block.startsWith('#### ')) {
      return <h4 dangerouslySetInnerHTML={{ __html: parseInlineMarkdown(block.replace('#### ', '')) }} />;
    } else if (block.startsWith('- ')) {
      return <li dangerouslySetInnerHTML={{ __html: parseInlineMarkdown(block.replace('- ', '')) }} />;
    } else if (block.startsWith('|')) {
      // Handle tables
      const columns = block.split('|').filter((col) => col.trim() !== '');
      return (
        <tr>
          {columns.map((col, index) => (
            <td key={index} dangerouslySetInnerHTML={{ __html: parseInlineMarkdown(col.trim())}} />
          ))}
        </tr>
      );
    } else if (block.startsWith('---')) {
      return <hr />; // Horizontal rule
    } else if (block.trim() === '') {
      return <br />; // Empty line
    } else {
      return <p dangerouslySetInnerHTML={{ __html: parseInlineMarkdown(block) }} />;
    }
  };

  // Split the markdown into blocks (paragraphs, lists, tables, etc.)
  const blocks = markdown.split('\n\n'); // Split by double newlines for blocks

  return (
    <div>
      {blocks.map((block, index) => (
        <React.Fragment key={index}>
          {block.split('\n').map((line, lineIndex) => (
            <React.Fragment key={lineIndex}>{renderBlockMarkdown(line)}</React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

export default MarkdownRenderer;